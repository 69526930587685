// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require('bootstrap');
import 'imports-loader?define=>false,module.exports=>false!jquery-validation';

// GLOBAL FG object
window.FG = {};


FG.selectGender = function (gender) {
  $('#' + gender + '_radio').prop('checked', true);
  $('.btn-outline-' + gender).addClass('active');
  $('.' + gender + '_icon').addClass('active');
}

FG.unselectGender = function (gender) {
  $('#' + gender + '_radio').prop('checked', false);
  $('.btn-outline-' + gender).removeClass('active');
  $('.' + gender + '_icon').removeClass('active');
}

FG.updateIcon = function (from, to) {
  if ($('.active_quiz').length === 0) return;
  var currentVal = $('input[type=radio]:checked').val();
  if (currentVal == undefined) {
    FG.selectGender(to);
  } else if (currentVal == from || currentVal == 'unsure') {
    FG.unselectGender('unsure');
    FG.unselectGender(from);
    FG.selectGender(to);
  }
}

FG.makeIconsClickable = function() {
  $(document).on('click', '.active_quiz .real_icon', function() {
    FG.updateIcon('synthetic', 'real')
  })

  $(document).on('click', '.active_quiz .synthetic_icon', function() {
    FG.updateIcon('real', 'synthetic');
  });
}

FG.highlightIconsOnBtnClick = function() {
  $(document).on('change', '.active_quiz input[type=radio]', function() {
    var currentVal = $('input[type=radio]:checked').val();
    if (currentVal == 'synthetic') {
      $('.real_icon').removeClass('active');
      $('.synthetic_icon').addClass('active');
    } else if (currentVal == 'real') {
      $('.synthetic_icon').removeClass('active');
      $('.real_icon').addClass('active');
    } else if (currentVal == 'unsure') {
      $('.synthetic_icon').removeClass('active');
      $('.real_icon').removeClass('active');
    }
  });
}

FG.moveToNextQuizPart = function () {
  if ($('.active_quiz').length === 0 && $('.btn-next-page').length !== 0) {
    var href = $('.btn-next-page')[0];
    if (href !== undefined) {
      Turbolinks.visit(href);
    }
  } else {
    $('#quiz_part_form').submit();
  }
}

FG.addKeyShortcuts = function() {
  $(document).on('keydown', function(e) {
    if ($('.quiz').length === 0) return;
    // console.log('e')
    switch (e.which) {
      case 37: // left - select real
        FG.updateIcon('synthetic', 'real');
        break;

      case 38: // up - select real
        FG.updateIcon('synthetic', 'real');
        break;

      case 39: // right - select synthetic
        FG.updateIcon('real', 'synthetic');
        break;

      case 40: // down - select synthetic
        FG.updateIcon('real', 'synthetic');
        break;

      case 13: // enter = Next or Submit
        FG.moveToNextQuizPart();
        break;

      case 32: //space = Next or Submit
        FG.moveToNextQuizPart();
        break;

      default:
        return; // exit this handler for other keys
    }
    e.preventDefault(); // prevent the default action (scroll / move caret)
  });
}

FG.validateQuizForm = function () {
  $('#quiz_part_form').validate({
    rules: {
      'quiz_part[user_answer]': { required: true },
    },
    errorPlacement: function(error, element) {
      return true;
    },
    submitHandler: function(form) {
      form.submit();
    }
  });
}

FG.remember_hide_keyboard_alert = function() {
  $(document).on('click', '.keyboard-alert .close', function() {
    href = $('.keyboard-alert').data('close_path');
    $.ajax({ type: 'PUT', url: href });
  });
};

FG.handle_keyboard_shortcuts = function() {
  $(document).on('focus', '#comment_box', function() {
    $(document).off('keydown')
  });
  $(document).on('focusout', '#comment_box', function() {
    FG.addKeyShortcuts();
  });
  
}

$(document).on('turbolinks:load', function() {
  FG.makeIconsClickable();
  FG.highlightIconsOnBtnClick();
  FG.handle_keyboard_shortcuts();
  FG.addKeyShortcuts();
  FG.validateQuizForm();
  FG.remember_hide_keyboard_alert();
})
